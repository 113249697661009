<template>
<el-dialog title="选择用户" :visible.sync="value" :close-on-click-modal="false" :before-close="dialogClose" width="800px">
  <div style="width:100%">
    <div class="search-box" style="text-align:left;">
      <el-form inline size="small">
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="名称、编号" style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>

      </el-form>
    </div>
    <el-table :data="list" 
    row-key="id" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column label="名称" prop="name" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="organizationOrgName" align="center" label="所属部门"></el-table-column>
      <el-table-column prop="phone" align="center" label="手机号/账号"></el-table-column>  
      <el-table-column prop="isDeliver" align="center" label="配送员" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.isDeliver==1 ?'是（'+scope.row.deliverUnitPrice+'）':'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-edit" @click="okSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
     
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
    <el-row>
    <el-col :span="24" style="margin-top:50px;">
        <el-button @click="dialogClose">关闭</el-button>
    </el-col>
    </el-row>
  </div>
</el-dialog>
</template>

<script>
import { getUserPage } from '@/api/index.js'
export default {
  name: 'chooseUser',
  components: {},
  props:  {
    value: String ,
    isDeliver: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showType: 0,
      editType: '',
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        organizationOrg:'1',
        isDeliver:''
      },
      list: [],
      total: 0,
    }
    
  },
  created() {
    this.form.isDeliver = this.isDeliver;
    this.getList();
  },
  methods: {
    dialogClose() {
      this.$emit('input', false)
    },
    okSelect(item){
      this.$emit('okSelected', item);
      this.$emit('input', false)
    },
    getList() {
      getUserPage(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        for(let item of this.list){
          item.originalId = item.id;
        }
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
  }
}
</script>

<style>
</style>