<template>
<el-dialog title="选择定位" :visible.sync="value" :close-on-click-modal="false" :before-close="dialogClose" width="840px">
  <div style="width:100%">
    <div class="search-box" style="text-align:left;">
      <el-form inline size="small">
        <el-form-item label="地址搜索" v-if="isEdit">
          <el-input v-model="address" placeholder="地名" style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="经度： ">
          <div style="width:80px;">
            {{selectPoint.lng}}
          </div>
        </el-form-item>
        <el-form-item label="纬度： ">
          <div style="width:80px;">
            {{selectPoint.lat}}
          </div>
        </el-form-item>
        <el-form-item label="地址： ">         
           <div style="width:200px;">
            {{selectPoint.address}}
            </div>
        </el-form-item>

      </el-form>
    </div>
    <baidu-map :style="{width:map.width,height:map.height}" class="map" ak="GHcnUToXnuwdGAAt8XACR6CWgkewTiSB" :zoom="map.zoom" :center="map.center"
 @ready="handler" :scroll-wheel-zoom="true" @click="mapClick">
 <bm-local-search :keyword="address" :auto-viewport="true" :location="location" :panel="false"></bm-local-search>
    </baidu-map>
    <el-row>
    <el-col :span="24" style="margin-top:10px;">
        <el-button @click="dialogClose">关闭</el-button>
        <el-button type="primary" @click="okSelect" v-if="isEdit">确定</el-button>
    </el-col>
    </el-row>
  </div>
</el-dialog>
</template>

<script>

import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'
import PointIcon from '@/assets/img/point.png'
import {  } from '@/api/index.js'
export default {
  name: 'bdMap',
  components: {BaiduMap,BmLocalSearch},
  props:  {
    value: Boolean ,
    lng: String,
    lat: String,
    isEdit:{
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      map:{
        width:'800px',
        height:'550px',
        center:{
          lng:'',
          lat:''
        },
        zoom:12,
        
      },
      address:'',
      location:'',
      pointMarker:'',
      selectPoint:{lng:'',lat:'',address:''}
    }
    
  },
  created() {
    this.map.center.lng = this.lng;
    this.map.center.lat = this.lat;
    this.selectPoint={lng:this.lng,lat:this.lat}
    if(this.lng){
      this.map.zoom = 15;
    }
  },
  mounted(){
   
  },
  methods: {
    loadPrePoint(){
      if(this.lng){
          let point = new BMap.Point(this.lng, this.lat)
          map.removeOverlay(this.pointMarker);
          let Icon_0 = new BMap.Icon(PointIcon, new BMap.Size(64, 64), {anchor: new BMap.Size(18, 32),imageSize: new BMap.Size(36, 25)});
          this.pointMarker = new BMap.Marker(point,{icon: Icon_0});
          map.addOverlay(this.pointMarker);
          this.pointMarker.setAnimation(BMAP_ANIMATION_BOUNCE);
          map.panTo(point);
        }
    },
    dialogClose() {
      this.$emit('input', false)
    },
    mapClick(e){
      if(!this.isEdit){
        return;
      }
      if(this.pointMarker){
        map.removeOverlay(this.pointMarker);
      }
      let Icon_0 = new BMap.Icon(PointIcon, new BMap.Size(64, 64), {anchor: new BMap.Size(18, 32),imageSize: new BMap.Size(36, 25)});
      this.pointMarker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat),{icon: Icon_0});
      map.addOverlay(this.pointMarker);
      map.panTo(e.point);
      this.pointMarker.setAnimation(BMAP_ANIMATION_BOUNCE);
      this.selectPoint = {lng:e.point.lng,lat:e.point.lat,address:''};

      let point = new BMap.Point(e.point.lng, e.point.lat);
      let gc = new BMap.Geocoder();
      let _this = this;
      gc.getLocation(point, function (rs) {
        if(rs.surroundingPois.length>0){
          _this.selectPoint.address = rs.surroundingPois[0].title;
        }else{
          _this.selectPoint.address = rs.address;
        }
        

      });


    },
    okSelect(){
      if(!this.selectPoint.lng){
         this.$message.error('请选择定位！');
        return;
      }
      this.$emit('okSelected', this.selectPoint);
      this.$emit('input', false)
    },
    handler ({BMap, map}) {
      let _this = this;  // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
      window.map = map; 
      if(this.lng){
       this.loadPrePoint();
      }else{
        let geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition(function(r){
          _this.map.center = {lng: r.longitude, lat: r.latitude};   // 设置center属性值
          _this.zoom=11;
          //_this.autoLocationPoint = {lng: r.longitude, lat: r.latitude};  // 自定义覆盖物
          //_this.initLocation = true;
        },{enableHighAccuracy: true})
      }
      
    },
  }
}
</script>

<style>
</style>